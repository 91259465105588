// React Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Components
import App from './App';
import { EmotionCacheProvider } from './components/EmotionCacheProvider/EmotionCacherProvider';
import CspHeader from './components/CspHeader/CspHeader';

// Types/Data Models

// API Services
import { Auth0Provider } from '@auth0/auth0-react';

// CSS Styles
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import './components/EditRequest/Common/customDatePickerStyle.css';

// UI Libraries
import { ChakraProvider, extendTheme, ThemeConfig, ColorModeScript } from '@chakra-ui/react';

// Assets
import Fonts from './fonts/customFont';

// Libraries
import crypto from 'crypto-random-string';

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const theme = extendTheme({
  fonts: {
    heading: 'Mukta-Bold',
    body: 'Mukta-Regular',
  },
  config,
});

const clientId = process.env.REACT_APP_CLIENT_ID as string;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const auth0AudiencePlatform = process.env.REACT_APP_AUTH0_AUDIENCE_PLATFORM as string;
export const auth0AudienceRequest = process.env.REACT_APP_AUTH0_AUDIENCE_REQUEST as string;

const nonce = crypto({ length: 16, type: 'url-safe' });

ReactDOM.render(
  <Router>
    <EmotionCacheProvider nonce={nonce}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <Fonts />
        <Auth0Provider
          domain={auth0Domain}
          authorizationParams={{
            audience: auth0AudiencePlatform,
            redirect_uri: window.location.origin,
            scope: "read:current_user",
          }}
          clientId={clientId}
          useRefreshTokens={false}
          >
          <CspHeader extraStyleNonce={nonce}/>
          <App />
        </Auth0Provider>
      </ChakraProvider>
    </EmotionCacheProvider>
  </Router>, document.getElementById("root")
);
