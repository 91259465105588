// React Libraries
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Switch} from 'react-router';

// Components
import Login from './components/Login/Login';
import NavBar from './components/NavBar/NavBar';
import SideMenu from './components/SideMenu/SideMenu';
const MainSectionSelect =  lazy(() =>  import ('./components/MainSection/MainSectionSelect'));
const MainSectionRequest =  lazy(() =>  import ('./components/MainSection/MainSectionRequest'));
const EditFeasibilityRequest =  lazy(() =>  import ('./components/EditRequest/FeasibilityRequest/EditFeasibilityRequest'));
const EditAccessibilityRequest =  lazy(() =>  import ('./components/EditRequest/AccessibilityRequest/EditAccessibilityRequest'));
const AllRequests =  lazy(() =>  import ('./components/AllRequests/AllRequests'));
const AssignBioBanks =  lazy(() =>  import ('./components/AssignBioBanks/AssignBioBanks'));
const AssignBioBankHandlers =  lazy(() =>  import ('./components/AssignBioBankHandlers/AssignBioBanksHandlers'));
const ViewAllResponses =  lazy(() =>  import ('./components/ViewAllResponses/ViewAllResponses'));

// Types/Data Models
import { CurrentUser } from './models/userModel';
import { auth0AudienceRequest, auth0AudiencePlatform } from './index';
import { ROLES } from './common/userPermissions.constants';

// API Services
import { useAuth0 } from '@auth0/auth0-react';
import setAuthToken, {getAuthToken} from './services/apiServices';
import platformService from './services/platformService';
import permissionService from './services/permissionService';
import jwtDecode from 'jwt-decode';
import projectService from './services/projectService';

// CSS Styles
import { REGULAR_PLUS_SIZE, MAIN_TOP_MARGIN } from './common/style.constants';

// UI Libraries
import { Box, Text } from '@chakra-ui/layout';
import { Alert, AlertIcon, SkeletonCircle, SkeletonText } from "@chakra-ui/react"
import { ProjectWithRequests } from './models/projectsModel';

// Assets
export const currentUserDefault: CurrentUser = {firstName: "", lastName: "", auth0Id: "", email: "", phone: "", id: "", lang: "", organisationId: "", state: "", permissions: [], roles: [], relatedBiobankId: null};
export const RoleContext = React.createContext("");
export const UserContext = React.createContext<CurrentUser>(currentUserDefault);

import './i18n/i18n';
import {REQUEST} from "./common/requests.constants";

export const RenderLoader = ():JSX.Element => {
  return (
    <Box height={'auto'}>
      <NavBar currentUser={currentUserDefault} />
      <Box mt={MAIN_TOP_MARGIN} mx='auto' maxWidth={'600px'}  w='100%'>
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
        <Text textAlign={'center'} mt={10} fontSize={REGULAR_PLUS_SIZE}>Fetching the latest requests for you!</Text>
      </Box>
    </Box>
  )
}

// eslint-disable-next-line no-empty-pattern
const App = ():JSX.Element => {
  const [activeResource, setActiveResource] = useState<string[]>([]);
  const [allProjects, setAllProjects] = useState<ProjectWithRequests[]>([]);
  const {
    isLoading: isAuth0Loading,
    isAuthenticated,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    user,
    logout
  } = useAuth0();

  const [currentUser, setCurrentUser] = useState<CurrentUser>({firstName: '', lastName: '', auth0Id: '', email: '', phone: '', id: '', lang: '', organisationId: "", state: "", permissions: [], roles: [], relatedBiobankId: null});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [updateProjects, setUpdateProjects] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedSidebar, setSelectedSidebar] = useState<string>('');

  useEffect(() => {
    const getToken = async (audience: string) => {
      try {
        return await getAccessTokenSilently({ authorizationParams: { audience }});
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.error === 'consent_required') {
          return await getAccessTokenWithPopup({ authorizationParams: { audience }});
        }
        if (e.error === 'login_required') {
          await loginWithPopup({ authorizationParams: { audience }});
        }
        throw new Error(e);
      }
    }
    /*
      Getting tokens from platform auth0 API and request auth0 API, both needed for
      accessing platform API and request API. Each operate with their own permissions tables.
    */
    const getTokens = async () => {
      setIsLoading(true);
      try {
        const tokenPlatformRes = await getToken(auth0AudiencePlatform);
        if (!tokenPlatformRes) {
          throw new Error('Failed to get Platform token');
        }

        setAuthToken(auth0AudiencePlatform, tokenPlatformRes);

        const tokenRequestRes =  await getToken(auth0AudienceRequest);
        if (!tokenRequestRes) {
          throw new Error('Failed to get Request token');
        }

        setAuthToken(auth0AudienceRequest, tokenRequestRes);

        if (user && user.sub && tokenPlatformRes) {
          getCurrentUser(user.sub);
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.error('Login failed:', e.message);
        if (e.error === 'access_denied') {
          logout();
        } else {
          setErrorMessage(e.message);
        }
      }
    };

    if (isAuthenticated) {
      getTokens();
    }
    /*
      Refreshing project list whenver updatedProjects state is set to true.
      This is needed for deleting/archiving/editing a request, as well as assignigning a request to project in the AllRequests component
    */
    if (updateProjects) {
      const organisationId = userRole === ROLES.BB_RELATED_STEERING_GROUP ? currentUser.relatedBiobankId : currentUser.organisationId;
      getProjects(organisationId ?? '', userRole, currentUser.id);
    }
  }, [user, updateProjects]);

  /*
    Getting current user from platfrom API in order to get the role of the user, which will be saved
    in RoleContext in order to access the current role and render accordingly.
  */
  const getCurrentUser = async (id: string) => {
    try {
      const currentUserRes: CurrentUser = await platformService.getCurrentUser(id);
      /*
        here we retrieve the platform token, decode it and get the permission which get then passed on to determineRole(), where
        we set the RoleContext
      */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedPlatformToken: any = jwtDecode(getAuthToken(auth0AudiencePlatform) as string);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedRequestToken: any = jwtDecode(getAuthToken(auth0AudienceRequest) as string);
      currentUserRes.permissions = [...decodedPlatformToken.permissions, ...decodedRequestToken.permissions];
      const role = permissionService.determineRole(currentUserRes);
      setUserRole(role);
      setCurrentUser(currentUserRes);

      const organisationId = role === ROLES.BB_RELATED_STEERING_GROUP ? currentUserRes.relatedBiobankId : currentUserRes.organisationId;
      getProjects(organisationId ?? '', role, currentUserRes.id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error fetching user: ', error.message);
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  }

  /*
    Getting latest project list from request API with request auth0 token.
    Sorting in ascending order (alphabetically)
  */
  const getProjects = async (companyId: string, role: string, userId: string ) => {
    if (currentUser !== undefined) {
      try {
        let allProjectsRes: ProjectWithRequests[] = [];
        if (role === ROLES.FINBB_COR || role === ROLES.FINBB_ADM || role === ROLES.FINBB_REP) {
          allProjectsRes = await projectService.getAllProjects();
        } else if (role === ROLES.BB_COR || role === ROLES.BB_REP || role === ROLES.BB_STEERING || role == ROLES.BB_RELATED_STEERING_GROUP) {
          allProjectsRes = await projectService.getBioBankProjects(companyId);
        } else if (role === ROLES.BB_HDLR) {
          // Handler AKA Editor
          allProjectsRes = await projectService.getBioBankHandlerProjects(userId);
        } else {
          console.error(`  No usable roles found. Got ${role}`);
        }

        if (allProjectsRes.length > 0) {
          const sortedProjects: ProjectWithRequests[] = allProjectsRes.slice().sort(function(a: ProjectWithRequests, b: ProjectWithRequests) {
            return a.name > b.name ? 1 : -1;
          });
          setAllProjects(sortedProjects);
        }
        setUpdateProjects(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('Error fetching projects:', error);
        setErrorMessage(error.message);
      }
    } else {
      console.error('Current user is undefined');
    }
  }

  /*
    Login screen if not authenticated and we didn't get the user from auth0 platform API
  */
  if (!isAuthenticated && !isAuth0Loading && !user) {
    return (
      <Login/>
    )
  }

  if (isLoading || isAuth0Loading) {
    return (
      <RenderLoader />
    )
  }

  if (errorMessage) {
    return (
      <Box height={'auto'}>
        <NavBar currentUser={ currentUser || currentUserDefault } />
        <Box mt={MAIN_TOP_MARGIN} mx='auto' maxWidth={'600px'}  w='100%'>
          <Alert status="error">
            <AlertIcon />
            {`There was an error processing your request: ${errorMessage}`}
          </Alert>
        </Box>
      </Box>
    )
  }

  return (
    <Box height={'auto'}>
      <NavBar currentUser={ currentUser || currentUserDefault } />
      {/*
        RoleContext provides current role of user, UserContext provides currentUser data,
        PlatformTokenContext/RequestTokenContext provide required tokens for platform API/request API
      */}
      <Suspense fallback={RenderLoader()}>
        <RoleContext.Provider value={userRole}>
        <UserContext.Provider value={(currentUser || currentUserDefault)}>
        {currentUser && allProjects && !isLoading &&
          <Switch>
            <>
              <Route exact path={["/", "/view/request/:id", "/view/feasibility-request/:id", "/view/access-request/:id"]}>
                <SideMenu
                  allProjects={allProjects}
                  setActiveResource={setActiveResource}
                  setSelectedSidebar={setSelectedSidebar}
                />
              </Route>

              {/*
                MainSectionSelect component is displayed when logging in, or when returning to home.
                MainSectionSelect prompts the user to either select a request from the project list (sidemenu), or to view all requests
                from the AllRequests component
              */}
              <Route exact path="/">
                <MainSectionSelect />
              </Route>

              <Route path="/view/feasibility-request/:id" render={() => (
                <MainSectionRequest
                  requestType={REQUEST.FEASIBILITY}
                  activeResource={activeResource}
                  currentUser={currentUser}
                  setUpdateProjects={setUpdateProjects}
                  selectedSidebar={selectedSidebar}
                  setSelectedSidebar={setSelectedSidebar}
                />
              )}>
              </Route>
              <Route path="/view/access-request/:id" render={() => (
                <MainSectionRequest
                  requestType={REQUEST.ACCESS}
                  activeResource={activeResource}
                  currentUser={currentUser}
                  setUpdateProjects={setUpdateProjects}
                  selectedSidebar={selectedSidebar}
                  setSelectedSidebar={setSelectedSidebar}
                />
                )}>
              </Route>

              {/* Edit FeasibilityRequest component/route only visible to user with finbb-admin/finbb-coordinator roles*/}
              {(userRole === ROLES.FINBB_ADM || userRole === ROLES.FINBB_COR || userRole === ROLES.BB_COR) &&
                <Route path="/edit-feasibility-request/:id" render={(props) => (
                  // eslint-disable-next-line react/prop-types
                  <EditFeasibilityRequest setUpdateProjects={setUpdateProjects} id={props.match.params.id} />
                  )}>
                </Route>
              }
              {/* EditAccessRequest component/route only visible to user with finbb-admin/finbb-coordinator roles*/}
              {(userRole === ROLES.FINBB_ADM || userRole === ROLES.FINBB_COR || userRole === ROLES.BB_COR) &&
                <Route path="/edit-accessibility-request/:id" render={(props) => (
                  // eslint-disable-next-line react/prop-types
                  <EditAccessibilityRequest setUpdateProjects={setUpdateProjects} id={props.match.params.id}/>
                  )}>
                </Route>
              }
              {/* AllRequest component/route only visible to user with finbb-admin/finbb-coordinator/finbb-reporter roles*/}
              {(userRole === ROLES.FINBB_ADM || userRole === ROLES.FINBB_COR || userRole === ROLES.FINBB_REP) &&
                <Route path="/view/all-requests">
                  <AllRequests setUpdateProjects={setUpdateProjects}/>
                </Route>
              }

              {/* AssignBioBank component/route only visible to user with finbb-admin/finbb-coordinator roles*/}
              {(userRole === ROLES.FINBB_ADM || userRole === ROLES.FINBB_COR) &&
                <Route path="/assign-biobanks/:id" render={(props) => (
                  <AssignBioBanks
                    // eslint-disable-next-line react/prop-types
                    id={props.match.params.id}
                    // eslint-disable-next-line react/prop-types
                    requestType={props.location.search.substring(props.location.search.indexOf('=') + 1)}
                  />
                )}>
                </Route>
              }
              {/* AssignBioBankHandlers component/route only visible to user with organisation-coordinator role*/}
              {userRole === ROLES.BB_COR &&
                <Route path="/assign-biobank-handlers/:id" render={(props) => (
                  <AssignBioBankHandlers
                    // eslint-disable-next-line react/prop-types
                    id={props.match.params.id}
                    // eslint-disable-next-line react/prop-types
                    requestType={props.location.search.substring(props.location.search.indexOf('=') + 1, props.location.search.lastIndexOf('?'))}
                    // eslint-disable-next-line react/prop-types
                    organisationId={props.location.search.substring(props.location.search.lastIndexOf('=') + 1)}
                  />
                )}>
                </Route>
              }


              <Route path='/view-responses/:id' render={(props) => (
                // eslint-disable-next-line react/prop-types
                <ViewAllResponses requestId={props.match.params.id} requestType={props.location.search.substring(props.location.search.indexOf('=') + 1)}/>
              )}>
              </Route>
            </>
          </Switch>
        }
        </UserContext.Provider>
        </RoleContext.Provider>
      </Suspense>
    </Box>

  );
};

export default App;
